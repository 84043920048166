import request from '@http';

// 2022/12/05 王江毅 门锁安装信息列表
export function getLockInstallList(params) {
    return request({
        method: 'GET',
        url: '/api/lock/install/list',
        params,
    });
}

// 2022/12/13 王江毅 根据小区查询房间
export function getApartmentListByCommunityUuid(params) {
    return request({
        method: 'GET',
        url: '/api/lock/install/apartment/list',
        params,
    });
}

// 2022/12/13 王江毅 新增安装门锁记录
export function lockInstallSave(data) {
    return request({
        method: 'POST',
        url: '/api/lock/install/save',
        data,
    });
}

// 2022/12/15 王江毅 门锁安装导出
export function lockInstallExport(params) {
    return request({
        method: 'GET',
        url: '/api/lock/install/export',
        params,
        responseType: 'blob',
    });
}

// 2022/12/26 王江毅 测试密码
export function testPassword(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/dc/apartment/passwords/clean/save/on/line',
        params,
    });
}

//2023/03/13 王江毅 房间已装修完毕
export function renovation(params) {
    return request({
        method: 'GET',
        url: '/api/lock/install/apartment/renovation',
        params,
    });
}